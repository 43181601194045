import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ManualView from "../components/Manual/Manual";

export default function Manual({ data }) {
  return (
    <Layout>
      <SEO title="Пояснюємо ризик-фактори" location={data.location} />
      <ManualView />
    </Layout>
  );
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
