import React from 'react';
import { Link } from 'gatsby'
import image1 from './manual-1.png';
import image2 from './manual-2.png';
import image3 from './manual-3.png';
import image4 from './manual-4.png';
import is_tennant_business_with_cheap_rent from './is_tennant_business_with_cheap_rent.jpeg';
import is_tennant_ngo_business from './is_tennant_ngo_business.jpeg';
import is_big_longations_after_2019 from './is_big_longations_after_2019.png';
import is_more_than_one_ngo_agreement_per_object from './is_more_than_one_ngo_agreement_per_object.jpeg';
import is_price_footnotes from './is_price_footnotes.jpeg';

import './styles.scss';
import { Fragment } from 'react';

const manual_data = [
    {
        image: image1, title: "Потенційна недоплата (помісячно)", description: "Від'ємна різниця між очікуваною вартістю оренди та фактичною вартістю оренди. Очікувану вартість оренди ми розраховуємо за формулою, а фактичну вартість оренди беремо із договору. Це значення показуємо лише тоді, коли очікувана вартість оредни перевищує фактичну вартість оренди."
    },
    {
        image: image2, title: "Некоректний код ЄДРПОУ орендаря", description: "Назва ризик-фактору говорить сама за себе. Код ЄДРПОУ має складатись із 8 символів. В той час як у значній кількості договорів оренди код ЄДРПОУ містить більше або менше восьми символів. Ризик заключається у тому, що по невірному коду може бути більш складно, або ж і зовсім неможливо встановити орендаря."
    },
    {
        image: image3, title: "Некоректний код ЄДРПОУ балансоутримувача", description: "Проблема дуже схожа на помилку у коді ЄДРПОУ орендаря. Ми поки не перевірили наскільки цей ризик є важливим у пошуку зради, але не змоги приховати цей сором від користувачів нашого сервісу.Проблема дуже схожа на помилку у коді ЄДРПОУ орендаря. Ми поки не перевірили наскільки цей ризик є важливим у пошуку зради, але не змоги приховати цей сором від користувачів нашого сервісу."
    },
    {
        image: image4, title: "Ризик заниженої оціночної вартості", description: "Оціночна вартість державного майна - показник, від якого залежить вартість оренди. Ми виявили що часто цим показником маніпулюють задля заниження орендної вартості. Розраховується наступним чином: якщо оціночна вартість одного метру квадратного (об'єкту з договору) коштує більш ніж у два рази дешевше ніж середня оціночна вартість одного метру квадратного по цьому ж цільовому призначенню - ми вважаємо цю оцінку ризикованою."
    },{
        image: is_tennant_business_with_cheap_rent,
        title: "Пільгова ставка для бізнесу",
        description: "Один з найбільш розповсюджених типів махінацій в оренді - укладання договорів під пільгове цільове призначення з низькою орендною ставкою (наприклад - для музею), при цьому займаючись в орендованих приміщеннях зовсім іншою діяльністю. Тому особливої уваги заслуговують договори оренди під музеї, бібліотеки чи архіви, укладені з орендарями, яким зазвичай притаманна комерційна діяльність (ТОВ, ТЗОВ, ПП та ФОП)."
    },
    {
        image: is_tennant_ngo_business,
        title: "Оренда бізнесом під потреби ГО",
        description: "Громадська, релігійна чи благодійна організації мають бути зареєстровані відповідно до норм законодавства в якості окремих юридичних осіб.Тому дуже дивно виглядають ситуації, коли з фізичними особами, ТОВ, ПП чи ТЗОВ, які створюються з метою отриманя прибутку, укладають угоди за цільовим призаченням для громадських, релігійних чи благодійних організацій."
    },
    {
        image: is_tennant_business_with_cheap_rent,
        title: "Пільгова ставка для бізнесу",
        description: "Один з найбільш розповсюджених типів махінацій в оренді - укладання договорів під пільгове цільове призначення з низькою орендною ставкою (наприклад - для музею), при цьому займаючись в орендованих приміщеннях зовсім іншою діяльністю. Тому особливої уваги заслуговують договори оренди під музеї, бібліотеки чи архіви, укладені з орендарями, яким зазвичай притаманна комерційна діяльність (ТОВ, ТЗОВ, ПП та ФОП)."
    },
    {
        image: is_big_longations_after_2019,
        title: "Тривале подовження у 2019-2020 рр.", description: `Починаючи з 2020 року, вся оренда державного майна відбувається за прозорими правилами через Інтернет-аукціони, проте укладені раніше договори продовжують діяти. При цьому для продовження дії договорів після завершення їх строку, також тепер необхідно проводити електронний аукціон. 
        Щоб уникнути ризику втрати приміщення через аукціон, багато орендарів намагались максимально продовжити дію договорів оренди до введення нових правил у дію - в 2019 та на початку 2020 років.`},
    {
        image: is_more_than_one_ngo_agreement_per_object,
        title: "Багато ГО за однією адресою",
        description: "Як показує практика, якщо в одному приміщення знаходиться багато договорів оренди для громадських, благодійних чи релігійних організацій, то досить часто в таких випадках існують різні зловживання, особливо - нецільове використання майна.Тому ми відмітили всі обєкти, в яких є 2 та більше договори оренди майна за однією адресою."},
    {
        image: is_price_footnotes,
        title: "Є уточнення до орендної ставки",
        description: `У полі "орендна ставка" переліків діючих договорів оренди державного майна має завжди бути числове значення.Проте, працівники регіональних відділень фонду Держмайна часто заповнювали ці поля різними символами(*;/;% тощо), або записували в це поле певні текстові коментарі.Подібне заповнення не дає змоги автоматично аналізувати дане поле, здійснюючи будь - які розрахунки, а крім цього, досить часто договори, заповнені таким чином, містять проблеми.`
    }    
];

const ListItem = (props) => {
    return <div className="view-manual__item">
            <div className="view-manual__item__image">
            <img src={props.image} alt={props.title}/>
            </div>

            <div className="view-manual__item__caption">
                <div className="view-manual__item__title">
                    {props.title}
                </div>
            <div className="view-manual__item__description view-text">
                {props.title === "Некоректний код ЄДРПОУ орендаря"
                    ? <>{props.description} Детальніше про цю проблему ми писали у <Link to="/posts/edrpou-codes">своїй статті</Link>.</>
                 : props.description}
                </div>
            </div>
        </div>
}

export default function Manual() {
    return(
        <div className="view-manual">
            <div className="view-container">
                <h1 className="view-title view-title--h1">
                    <span>Методика</span><br />розрахунку показників
                </h1>
                {manual_data.map((d, i) => <Fragment key={`${i}-${d.title}`}>
                    {ListItem(d)}
                </Fragment>)}
            </div>
        </div>
    );
}